var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { app: "", dark: "", color: "primary" } },
        [
          _c("v-app-bar-nav-icon", { on: { click: _vm.toggleDrawer } }),
          _c("v-toolbar-title", [
            _vm._v("八幡ねじ 在庫管理システム v" + _vm._s(_vm.currentVersion)),
          ]),
          _c("v-spacer"),
          _vm.weekInfo
            ? _c("div", [
                _c("b", [
                  _vm._v(
                    _vm._s(_vm.weekInfo.now.year) +
                      "年" +
                      _vm._s(_vm.weekInfo.week) +
                      "週"
                  ),
                ]),
                _vm._v(
                  " （" +
                    _vm._s(_vm.weekInfo.from.year) +
                    "/" +
                    _vm._s(_vm.weekInfo.from.month) +
                    "/" +
                    _vm._s(_vm.weekInfo.from.day) +
                    "(" +
                    _vm._s(_vm.weekdays[_vm.weekInfo.from.weekday]) +
                    ")〜 " +
                    _vm._s(_vm.weekInfo.to.year) +
                    "/" +
                    _vm._s(_vm.weekInfo.to.month) +
                    "/" +
                    _vm._s(_vm.weekInfo.to.day) +
                    "(" +
                    _vm._s(_vm.weekdays[_vm.weekInfo.to.weekday]) +
                    ") ） "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "", "mini-variant": _vm.miniDrawer, width: "300" },
          model: {
            value: _vm.showDrawer,
            callback: function ($$v) {
              _vm.showDrawer = $$v
            },
            expression: "showDrawer",
          },
        },
        [
          _c(
            "v-layout",
            { attrs: { column: "", "fill-height": "" } },
            [
              _c(
                "v-list",
                { attrs: { flat: "" } },
                [
                  !_vm.miniDrawer
                    ? _c("v-subheader", [_vm._v("Main menu")])
                    : _vm._e(),
                  _vm._l(_vm.menuItems, function (item) {
                    return _c(
                      "v-list-item",
                      {
                        key: item.title,
                        attrs: { color: "primary", to: item.to },
                      },
                      [
                        _c(
                          "v-list-item-action",
                          [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(item.title)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c("v-divider"),
              _vm.loggedIn && _vm.hasAdminAccess
                ? _c(
                    "v-list",
                    { attrs: { subheader: "" } },
                    [_c("v-subheader", [_vm._v("Admin menu ※準備中")])],
                    1
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-list",
                [
                  _vm.loggedIn
                    ? _c(
                        "v-list-item",
                        { on: { click: _vm.logout } },
                        [
                          _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v("mdi-logout")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("ログアウト")])],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-divider"),
                  _c(
                    "v-list-item",
                    { on: { click: _vm.switchMiniDrawer } },
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-icon", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.miniDrawer
                                  ? "mdi-chevron-right"
                                  : "mdi-chevron-left"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("折りたたむ")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-main",
        [
          _c(
            "keep-alive",
            [_c("router-view", { attrs: { duct: _vm.duct } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }